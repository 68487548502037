// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// All rights reserved for TerriFlux SARL
// ==================================================================================================
// External imports
import React from 'react';
// OpenSankey+ imports
import { initializeReinitializationOSP } from './deps/OpenSankey+/ModulesOSP';
import { AFMEditionMenu } from './components/AFMSankeyMenu';
import { SupplyUseModelisationProd } from './components/SankeyReconciliation';
/**
 * Initialisation of application data classe
 * @param {*} initial_data
 * @return {*}
 */
export const initializeApplicationDataMFA = (new_data_mfa, initial_data) => {
    // Init application data
    //const new_data_mfa = new Class_ApplicationDataMFA(window.SankeyToolsStatic)
    // Read data from cache if it exist
    if (initial_data !== undefined) {
        new_data_mfa.fromJSON(initial_data);
    }
    return new_data_mfa;
};
export const initializeReinitializationMFA = (new_data) => {
    return () => {
        initializeReinitializationOSP(new_data)();
    };
};
export const initializeAdditionalMenusMFA = (additionalMenus, new_data) => {
    // No initialisation if static --------------------------------------------------------
    if (new_data.is_static) {
        return;
    }
    // OpenSankey & OpenSankey+ initialisation --------------------------------------------
    // initializeAdditionalMenusOSP(
    //   additionalMenus,
    //   new_data
    // )
    // Data -------------------------------------------------------------------------------
    const new_data_mfa = new_data;
    // const {units_names} = applicationData.data as AFMSankeyData
    // let units = undefined
    // if (units_names && units_names.length > 0 && units_names[0].length > 0) {
    //   units = [units_names[0][0], 'Naturelles']
    // }
    // additionalMenus.additional_link_appearence_items.push(<FreeVariables
    //   t={applicationContext.t}
    //   applicationData={applicationData as MFASankeyApplicataionDataType}
    //   applicationState={applicationState}
    //   node_function={node_function}
    //   link_function={link_function}
    // />)
    // Configuration Menu
    // additionalMenus.additional_configuration_menus.push(<AFMMenuConfigurationImportExport
    //   applicationData={applicationData as MFASankeyApplicataionDataType}
    //   t={applicationContext.t}
    //   node_function={node_function}
    //   link_function={link_function}
    // />)
    // addon_link_visual_filter_toolbar.push(displayFNL)
    if (new_data_mfa.has_mfa) {
        additionalMenus.sankey_menus['afm'] = React.createElement(AFMEditionMenu, { application_data_mfa: new_data_mfa });
    }
    // additionalMenus.sankey_menus['unité'] = <UnitsSelector
    //   data={applicationData.data as AFMSankeyData}
    //   set_data={applicationData.set_data as (d:AFMSankeyData)=>void}
    //   units={units as string[]}
    // />
};
export const ModuleDialogsAFM = (new_data, additional_menus, menu_configuration_nodes_attributes, processFunctions) => {
    // Get OpenSankey+ et OpenSankey modules
    // const module_dialogs = moduleDialogsOSP(
    //   new_data,
    //   additional_menus,
    //   menu_configuration_nodes_attributes,
    //   processFunctions
    // )
    // Cast type
    const new_data_mfa = new_data;
    // Add MFA modules
    return [React.createElement(SupplyUseModelisationProd, { application_data_mfa: new_data_mfa, postProcessLoadExcel: () => null, launch: processFunctions.launch })];
    // module_dialogs.push(
    //   <SplitVerticalModal
    //     data = {applicationData.data as AFMSankeyData}
    //     ref_setter_show_split_trade = {
    //       (dict_hook_ref_setter_show_dialog_components as AFMSankeyShowMenuComponentsType).ref_setter_show_split_trade
    //     } />
    // )
    //return module_dialogs
};
