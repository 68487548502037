export const resources_metatags = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      metatags: {
        title: 'SankeySuite - Present your data at a glance',
        kw: 'sankey, sankey diagram, sankey charts, sankey graphs, flow charts, flow graphs, flow visualization, data flow, data graphs',
        descr: 'SankeySuite makes it easy to create Sankey and flow diagrams from your data.',
        subject: 'Application to draw Sankey diagram',
        image: '/logos/logo_opensankey.png',
        name: 'SankeySuite'
      }
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      metatags: {
        title: 'SankeySuite - Réconcilier vos flux en un clin d\'oeil',
        kw: 'sankey, diagramme de sankey, diagrammes de sankey, graphiques de sankey, diagrammes de flux, graphiques de flux, visualisation de flux, flux de données, graphiques de données',
        descr: 'SankeySuite permet de créer simplement des diagrammes de Sankey et de flux à partir de vos données.',
        subject: 'Application de creation de diagrammes de Sankey',
        image: 'logos/logo_opensankey.png',
        name: 'SankeySuite'
      }
    }
  }
}