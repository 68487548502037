var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import { LuBadgeAlert } from 'react-icons/lu';
import { Box, Button, Card, CardBody, CardHeader, ChakraProvider, FormControl, FormErrorMessage, Image, Input, InputGroup, InputLeftAddon, InputRightElement, Spinner, useDisclosure, } from '@chakra-ui/react';
// import { Class_ApplicationDataSA } from '../../types/ApplicationDataSA'
// import { returnToApp } from '../../AppSA'
//import { Theme_SankeyApplication } from '../../chakra/Theme'
import { logError, userSignUp, userValidate } from './RegisterFunctions';
import TermsOfUse from './TermsOfUse';
import { Presentation } from './Presentation';
export const email_regex_str = '(^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]{2,4}$)';
export const pwd_regex_str = '^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\n\r\t]).{8,}$';
export const name_regex_str = '^[a-zéèêïA-Z]+([ ,.\'-][a-zéèêïA-Z]+)*[a-zéèêïA-Z]*$';
export const lic_regex_str = '^([a-zA-Z0-9- ]{2,})$';
/**
 * Registering page
 * @param {*} {
 *   new_data_app
 * }
 * @return {*}
 */
const Register = ({ t, logo, logo_sankey_plus, loginComponent, noLicenceAccountRequired, setUpdate, returnToApp, theme }) => {
    // Step to register
    const [on_wait, setOnWait] = useState(false);
    const [registerStep, setRegisterStep] = useState(0);
    // License registrering informations
    const [user_name, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [firstname, setUserFirstName] = useState('');
    const [lastname, setUserLastName] = useState('');
    const captchaRef = useRef(null);
    // Initialise navigation function
    const navigate = useNavigate();
    // Terms of use modal
    const { isOpen, onOpen, onClose } = useDisclosure();
    // Ok for account creation
    const [ok_email, setOkEmail] = useState(0);
    const [ok_password, setOkPassword] = useState(0);
    const [show_password, setShowPassword] = useState(false);
    const [ok_firstname, setOkFirstName] = useState(0);
    const [ok_lastname, setOkLastName] = useState(0);
    const [ok_terms_of_uses, setOkTermsOfUses] = useState(false);
    const [ok_captcha, setOkCaptcha] = useState(false);
    const [ok_account_created, setOkAccountCreated] = useState(false);
    const okAccountInfos = (ok_email > 1) &&
        (ok_password > 1) &&
        (ok_firstname > 1) &&
        (ok_lastname > 1) &&
        ok_terms_of_uses &&
        ok_captcha &&
        (!ok_account_created);
    // Handler : License registrering
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        // step 0 : License presentation
        if (registerStep === 0) {
            setRegisterStep(1);
        }
        // step 1 : Get user infos
        else if (registerStep === 1) {
            if (okAccountInfos) {
                // backend SignUp
                setOnWait(true);
                yield userSignUp(user_name, password, firstname, lastname, (ok) => {
                    setOkAccountCreated(ok);
                })
                    .then(() => setOnWait(false));
            }
            else {
                logError(t('Register.account.msg.err_captcha'));
            }
        }
    });
    // Token validation
    const [searchParams,] = useSearchParams();
    const token = searchParams.get('t');
    if (token && registerStep !== 2) {
        setRegisterStep(2);
        userValidate(token, loginComponent, navigate, setUpdate);
    }
    // Loging message
    const log = React.createElement(React.Fragment, null,
        React.createElement("div", { className: 'LogError', style: {
                'color': 'red',
                'justifySelf': 'center',
                'textAlign': 'center'
            } }),
        React.createElement("div", { className: 'LogInfo', style: {
                'color': 'green',
                'justifySelf': 'center',
                'textAlign': 'center'
            } }));
    const osplus_presentation = [
        React.createElement(Presentation, { t: t, logo_sankey_plus: logo_sankey_plus }),
        React.createElement(Box, { display: "inline-grid" }, noLicenceAccountRequired ? React.createElement(Button, { variant: 'btn_lone_navigation_tertiary', maxWidth: 'inherit', width: 'fit-content', onClick: () => window.location.href = 'mailto:contact@terriflux.fr?subject=Demande de devis' }, t('Register.presentation.btn_next')) : React.createElement(Button, { variant: 'btn_lone_navigation_tertiary', maxWidth: 'inherit', width: 'fit-content', type: 'submit', onClick: handleSubmit }, t('Register.presentation.btn_next')))
    ];
    const register_form = [
        // {/* User e-mail*/}
        React.createElement(FormControl, { isInvalid: ok_email === 1 },
            React.createElement(InputGroup, { variant: 'register_input' },
                React.createElement(InputLeftAddon, null, t('Register.account.id.label')),
                React.createElement(Input, { isRequired: true, type: 'email', placeholder: t('Register.account.id.placeholder'), onChange: e => {
                        // Control e-amil format
                        if (e.target.value.match(email_regex_str) != null) {
                            setUserName(e.target.value);
                            setOkEmail(2);
                        }
                        else {
                            setUserName('');
                            setOkEmail(1);
                        }
                    } })),
            (ok_email === 1) ? (React.createElement(FormErrorMessage, null, t('Register.account.id.error'))) : (React.createElement(React.Fragment, null))),
        // {/* User password*/}
        React.createElement(FormControl, { isInvalid: ok_password === 1 },
            React.createElement(InputGroup, { variant: 'register_input' },
                React.createElement(InputLeftAddon, null, t('Register.account.pwd.label')),
                React.createElement(Input, { isRequired: true, type: show_password ? 'text' : 'password', placeholder: t('Register.account.pwd.placeholder'), onChange: e => {
                        if (e.target.value.match(pwd_regex_str) != null) {
                            setPassword(e.target.value);
                            setOkPassword(2);
                        }
                        else {
                            setPassword('');
                            setOkPassword(1);
                        }
                    } }),
                React.createElement(InputRightElement, { width: '4.5rem', marginRight: '0.25em' },
                    React.createElement(Button, { h: '1.75rem', size: 'sm', border: '0px', bg: 'gray.50', onClick: () => setShowPassword(!show_password) }, show_password ? t('Register.account.pwd.hide') : t('Register.account.pwd.show')))),
            (ok_password === 1) ? (React.createElement(FormErrorMessage, null, t('Register.account.pwd.error'))) : (React.createElement(React.Fragment, null))),
        // {/* User first name  */}
        React.createElement(FormControl, { isInvalid: ok_firstname === 1 },
            React.createElement(InputGroup, { variant: 'register_input' },
                React.createElement(InputLeftAddon, null, t('Register.account.fn')),
                React.createElement(Input, { isRequired: true, type: 'text', onChange: e => {
                        // Control format
                        if (e.target.value.match(name_regex_str) != null) {
                            setUserFirstName(e.target.value);
                            setOkFirstName(2);
                        }
                        else {
                            setUserFirstName('');
                            setOkFirstName(1);
                        }
                    } }))),
        // {/* User last name  */}
        React.createElement(FormControl, { isInvalid: ok_lastname === 1 },
            React.createElement(InputGroup, { variant: 'register_input' },
                React.createElement(InputLeftAddon, null, t('Register.account.ln')),
                React.createElement(Input, { isRequired: true, type: 'text', onChange: e => {
                        // Control format
                        if (e.target.value.match(name_regex_str) != null) {
                            setUserLastName(e.target.value);
                            setOkLastName(2);
                        }
                        else {
                            setUserLastName('');
                            setOkLastName(1);
                        }
                    } }))),
        // {/* Acceptance of terms of uses */}
        React.createElement(Button, { onClick: onOpen, leftIcon: (ok_terms_of_uses === true) ? React.createElement(FaCheck, null) : React.createElement(LuBadgeAlert, null) }, t('Register.account.btn_terms')),
        // {/* Captcha */}
        React.createElement("div", { className: 'form_group_recaptcha' },
            React.createElement(ReCAPTCHA, { sitekey: "6Les5JwmAAAAAOi3F8DLW4Z1aoqVMDBC9WpN1KFe", ref: captchaRef, onChange: () => {
                    var _a;
                    const token_captcha = (_a = captchaRef.current) === null || _a === void 0 ? void 0 : _a.getValue();
                    const fetchData = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            'token': token_captcha
                        })
                    };
                    const path = window.location.origin;
                    const url = path + '/auth/signup/check_captcha';
                    fetch(url, fetchData)
                        .then(r => {
                        r.json()
                            .then(t => {
                            setOkCaptcha(t['success']);
                        });
                    })
                        .catch(error => {
                        console.error('Error in checkCaptcha - ' + error.toString());
                        setOkCaptcha(false);
                    });
                } })),
        // Info / Error logs
        log,
        // Validation button
        React.createElement(Box, { display: "inline-grid" },
            React.createElement(Button, { isDisabled: !okAccountInfos || on_wait, variant: 'btn_lone_navigation_tertiary', type: 'submit', onClick: handleSubmit }, on_wait ?
                React.createElement(Spinner, null) :
                t('Register.account.btn_next'))),
    ];
    let header = '404 not found';
    let content = [React.createElement(React.Fragment, null)];
    let width = '33vw';
    if (registerStep === 0) {
        header = t('Register.presentation.title');
        content = osplus_presentation;
        width = '50vw';
    }
    else if (registerStep === 1) {
        header = t('Register.account.title');
        content = register_form;
    }
    else if (registerStep === 2) {
        header = t('Register.validation.title');
        content = [log];
    }
    let template = 'minmax(7vw, 150px) auto 11rem 11rem';
    if (noLicenceAccountRequired) {
        template = 'minmax(7vw, 150px) auto 11rem';
    }
    return (React.createElement(ChakraProvider, { theme: theme },
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: template },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'left' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, null),
                !noLicenceAccountRequired ? React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => { returnToApp(navigate); } }, t('UserNav.to_app')) : React.createElement(React.Fragment, null),
                React.createElement(Button, { variant: 'btn_lone_navigation_secondary', onClick: () => navigate('/login') }, t('UserNav.to_con')))),
        React.createElement("div", { className: "login-wrapper" },
            React.createElement(Card, { variant: 'card_register', marginTop: '100px', width: width },
                React.createElement(CardHeader, { style: { 'textAlign': 'center' } }, header),
                React.createElement(CardBody, null, content.map((e, i) => { return React.createElement(React.Fragment, { key: i }, e); })))),
        React.createElement(TermsOfUse, { isOpen: isOpen, onClose: onClose, setOk: setOkTermsOfUses })));
};
export default Register;
