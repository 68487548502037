var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import i18next from 'i18next';
import { Box, Button, Card, CardBody, CardHeader, FormControl, FormErrorMessage, Image, Input, InputGroup, InputLeftAddon, InputRightElement, Spinner } from '@chakra-ui/react';
import { email_regex_str, pwd_regex_str } from '../Register/Register';
import { applyPasswordReset, triggerPasswordReset } from './LoginFunctions';
// Password resetin page
export const PasswordResetFromToken = ({ t, logo, returnToApp }) => {
    // Get Token from param
    const { token } = useParams();
    // States
    const [on_wait, setOnWait] = useState(false);
    const [password, setPassword] = useState('');
    const [ok_password, setOkPassword] = useState(0);
    const [show_password, setShowPassword] = useState(false);
    // Initialise navigation function
    const navigate = useNavigate();
    // Handler
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (token !== undefined) {
            setOnWait(true);
            const lang = i18next.language;
            yield applyPasswordReset(t, token, {
                password,
                lang
            }, () => { navigate('/login'); })
                .then(() => setOnWait(false));
        }
    });
    // React output
    return (React.createElement("div", null,
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: 'minmax(7vw, 150px) auto 11rem 11rem' },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'center' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, null),
                React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => returnToApp(navigate) }, t('UserNav.to_app')),
                React.createElement(Button, { variant: 'btn_lone_navigation_secondary', onClick: () => navigate('/login') }, t('UserNav.to_con')))),
        React.createElement("div", { className: "login-wrapper" },
            React.createElement(Card, { variant: 'card_register', width: '33vw' },
                React.createElement(CardHeader, { style: { 'textAlign': 'center' } }, t('Login.forgot.title')),
                React.createElement(CardBody, null,
                    React.createElement(FormControl, { isInvalid: ok_password === 1 },
                        React.createElement(InputGroup, { variant: 'register_input' },
                            React.createElement(InputLeftAddon, null, t('Register.account.pwd.label')),
                            React.createElement(Input, { isRequired: true, type: show_password ? 'text' : 'password', placeholder: t('Register.account.pwd.placeholder'), onChange: e => {
                                    if (e.target.value.match(pwd_regex_str) != null) {
                                        setPassword(e.target.value);
                                        setOkPassword(2);
                                    }
                                    else {
                                        setPassword('');
                                        setOkPassword(1);
                                    }
                                } }),
                            React.createElement(InputRightElement, { width: '4.5rem', marginRight: '0.25em' },
                                React.createElement(Button, { h: '1.75rem', size: 'sm', border: '0px', bg: 'gray.50', onClick: () => setShowPassword(!show_password) }, show_password ? t('Register.account.pwd.hide') : t('Register.account.pwd.show')))),
                        (ok_password === 1) ? (React.createElement(FormErrorMessage, null, t('Register.account.pwd.error'))) : (React.createElement(React.Fragment, null))),
                    React.createElement(Button, { variant: 'btn_lone_navigation_tertiary', type: "submit", onClick: () => {
                            handleSubmit();
                        } }, t('Login.forgot.sub')),
                    React.createElement("div", { className: 'LogError', style: {
                            'color': 'red',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }),
                    React.createElement("div", { className: 'LogInfo', style: {
                            'color': 'green',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }),
                    on_wait ?
                        React.createElement(Spinner, null) :
                        React.createElement(React.Fragment, null))))));
};
// Password resetin page
export const PasswordResetFromMail = ({ t, logo, returnToApp }) => {
    // States
    const [on_wait, setOnWait] = useState(false);
    const [email, setEmail] = useState('');
    const [ok_email, setOkEmail] = useState(0);
    // Initialise navigation function
    const navigate = useNavigate();
    // Handler
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        const lang = i18next.language;
        yield triggerPasswordReset(t, {
            email,
            lang
        }, () => { navigate('/login'); })
            .then(() => setOnWait(false));
    });
    // React output
    return (React.createElement("div", null,
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: 'minmax(7vw, 150px) auto 11rem 11rem' },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'center' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, null),
                React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => returnToApp(navigate) }, t('UserNav.to_app')),
                React.createElement(Button, { variant: 'btn_lone_navigation_secondary', onClick: () => navigate('/login') }, t('UserNav.to_con')))),
        React.createElement("div", { className: "login-wrapper" },
            React.createElement(Card, { variant: 'card_register', width: '33vw' },
                React.createElement(CardHeader, { style: { 'textAlign': 'center' } }, t('Login.forgot.title')),
                React.createElement(CardBody, null,
                    React.createElement(FormControl, { isInvalid: ok_email === 1 },
                        React.createElement(InputGroup, { variant: 'register_input' },
                            React.createElement(InputLeftAddon, null, t('Login.id.label')),
                            React.createElement(Input, { isRequired: true, type: 'email', placeholder: t('Login.id.placeholder'), onChange: e => {
                                    // Control e-amil format
                                    if (e.target.value.match(email_regex_str) != null) {
                                        setEmail(e.target.value);
                                        setOkEmail(2);
                                    }
                                    else {
                                        setEmail('');
                                        setOkEmail(1);
                                    }
                                } })),
                        (ok_email === 1) ? (React.createElement(FormErrorMessage, null, t('Login.id.error'))) : (React.createElement(React.Fragment, null))),
                    React.createElement(Button, { variant: 'btn_lone_navigation_tertiary', type: "submit", onClick: () => {
                            handleSubmit();
                        } }, t('Login.forgot.sub')),
                    React.createElement("div", { className: 'LogError', style: {
                            'color': 'red',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }),
                    React.createElement("div", { className: 'LogInfo', style: {
                            'color': 'green',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }),
                    on_wait ?
                        React.createElement(Spinner, null) :
                        React.createElement(React.Fragment, null))))));
};
