var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useCallback } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Box, Button, Card, CardBody, CardHeader, Image, Spinner, } from '@chakra-ui/react';
import { createSubscription, getStripePublishableKey } from './PaiementFunctions';
import { Presentation } from '../Register/Presentation';
/**
 * Component that embed stripe paiement page.
 * @return {*}
 */
export const PaiementCheckout = () => {
    // States
    const [publishableKey, setPublishableKey] = useState('');
    let stripePromise = undefined;
    if (publishableKey !== '')
        stripePromise = loadStripe(publishableKey);
    // Effects
    const fetchPublishableKey = () => __awaiter(void 0, void 0, void 0, function* () {
        const key = yield getStripePublishableKey();
        setPublishableKey(key);
    });
    useEffect(() => {
        fetchPublishableKey();
    }, []);
    const fetchClientSecret = useCallback(createSubscription, []);
    const options = { fetchClientSecret };
    return (React.createElement("div", { id: "checkout" }, (stripePromise === undefined) ?
        React.createElement(Spinner, null) :
        React.createElement(EmbeddedCheckoutProvider, { stripe: stripePromise, options: options },
            React.createElement(EmbeddedCheckout, null))));
};
/**
 * Create the right redirection after paiement.
 * Ie. if paiement succeeded or not.
 *
 * @return {*}
 */
export const PaiementReturn = () => {
    const [status, setStatus] = useState(null);
    const [searchParams,] = useSearchParams();
    useEffect(() => {
        const sessionId = searchParams.get('session_id');
        fetch(`/stripe/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then((data) => {
            setStatus(data.status);
        });
    }, []);
    if (status === 'open') {
        return (React.createElement(Navigate, { to: "/checkout" }));
    }
    if (status === 'complete') {
        return (React.createElement(Navigate, { to: "/license?p=success" }));
    }
    return null;
};
/**
 * Component that displayed paiement pages :
 * Trigger buy, succes paiement or error on paiement
 *
 * @param {*} {
 *   new_data_app
 * }
 * @return {*}
 */
export const PaiementPage = ({ t, logo, logo_sankey_plus, returnToApp }) => {
    // App data
    const [searchParams,] = useSearchParams();
    // Initialise navigation function
    const navigate = useNavigate();
    const goToCheckout = () => { navigate('/license/checkout'); };
    // Init what is displayed
    const status = searchParams.get('p');
    let content, header;
    if (status === 'buy') {
        header = t('Paiement.win_header_buy');
        content = React.createElement(React.Fragment, null,
            React.createElement(Box, null, t('Paiement.win_content_buy')),
            React.createElement(Presentation, { t: t, logo_sankey_plus: logo_sankey_plus }),
            ",",
            React.createElement(Box, { display: "inline-grid" },
                React.createElement(Button, { variant: 'btn_lone_navigation_tertiary', type: "submit", onClick: goToCheckout }, t('Paiement.btn_checkout'))));
    }
    else if (status === 'success') {
        header = t('Paiement.win_header_success');
        content = React.createElement(Box, null, t('Paiement.win_content_success'));
    }
    else {
        header = t('Paiement.win_header_error');
        content = React.createElement(Box, null, t('Paiement.win_content_error'));
    }
    // Page
    return (React.createElement("div", null,
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: 'minmax(7vw, 150px) auto 11rem' },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'center' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, null),
                React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => returnToApp(navigate) }, t('UserNav.to_app')))),
        React.createElement("div", { className: "login-wrapper" },
            React.createElement(Card, { variant: 'card_register', width: '33vw' },
                React.createElement(CardHeader, { style: { 'textAlign': 'center' } }, header),
                React.createElement(CardBody, null,
                    React.createElement("div", { style: { 'textAlign': 'center' } }, content))))));
};
