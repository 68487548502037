import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, } from '@chakra-ui/react';
/**
 * Register : Modal for terms of use
 *
 * @param {*} {
 *   isOpen,
 *   onClose,
 *   setOk
 * }
 * @return {*}
 */
const TermsOfUse = ({ isOpen, onClose, setOk }) => {
    // Initialise traduction function
    const { t } = useTranslation();
    const [htmlContent, setHtmlContent] = useState('');
    useEffect(() => {
        // Fetch the HTML content from the public directory
        const path = window.location.origin + '/terms_of_uses/terms_of_uses_' + i18next.language + '.html';
        fetch(path, {
            method: 'GET'
        }).then((response) => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const text = response.text();
            return text;
        })
            .then((html) => {
            setHtmlContent(html);
        })
            .catch(error => {
            console.error('Error in TermsOfUse - ' + error.toString());
        });
    }, []);
    return (React.createElement("div", null,
        React.createElement(Modal, { variant: 'modal_welcome', isOpen: isOpen, onClose: onClose },
            React.createElement(ModalOverlay, null),
            React.createElement(ModalContent, { maxWidth: '80vw' },
                React.createElement(ModalHeader, null, t('terms_of_uses.title')),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, null,
                    React.createElement(Box, { padding: '1rem', overflowY: 'scroll', height: 'calc(65vh - 3rem)' },
                        React.createElement("div", { dangerouslySetInnerHTML: { __html: htmlContent } }))),
                React.createElement(ModalFooter, null,
                    React.createElement(Button, { variant: 'menuconfigpanel_option_button', onClick: () => {
                            setOk(true);
                            onClose();
                        } }, t('terms_of_uses.accept')))))));
};
export default TermsOfUse;
