export const resources_template = {
  //=======================================================
  //EN
  //=======================================================
  en: {
    translation: {
      templates: {
        need_osp: 'OpenSankey+ needed',
        fr: 'Diagram template in french',
        en: 'Diagram template in english',
        themes: {
          energy: 'Energy',
          enviro: 'Environment',
          mat: 'Materials',
          industry: 'Industry',
          economy: 'Economics',
          ress: 'Ressources',
          commercial: 'Commercial',
          supply: 'Supply chain',
          agri: 'Agriculture',
          transport: 'Transports'
        },
        difficulties: {
          basic: 'Essential',
          intermediary: 'Advanced',
          advanced: 'Expert',
        },
        ids: {
          transport_dispatch: 'Transport flows',
          power_grid_with_disagregation: 'Power grid with productions & uses disaggregation',
          power_grid: 'Power grid',
          waste_recycling: 'Household packaging waste recycling',
          water_usage: 'Water ressource systeme',
          personnal_budget: 'Personal expenses',
          business_simple: 'Simple business accounting',
          commercial_pipe: 'Business pipe',
          production_of_boats: 'Sailboats production'
        },
      },
    }
  },
  //=======================================================
  //FR
  //=======================================================
  fr: {
    translation: {
      templates: {
        need_osp: 'OpenSankey+ necessaire',
        fr: 'Modèle de diagramme en français',
        en: 'Modèle de diagramme en anglais',
        themes: {
          energy: 'Energie',
          enviro: 'Environnement',
          mat: 'Matières',
          industry: 'Industrie',
          economy: 'Economique',
          ress: 'Ressources',
          commercial: 'Commercial',
          supply: 'Logistique',
          agri: 'Agriculture',
          transport: 'Transports'
        },
        difficulties: {
          basic: 'Basique',
          intermediary: 'Avancé',
          advanced: 'Expert',
        },
        ids: {
          transport_dispatch: 'Flux de transports',
          power_grid_with_disagregation: 'Réseau électrique avec désagrégation des productions et des utilisations',
          power_grid: 'Réseau électrique',
          waste_recycling: 'Recyclage des déchets d\'emballages',
          water_usage: 'Usage des ressources en eau',
          personnal_budget: 'Dépenses personnelles',
          business_simple: 'Bilan comptable simple',
          commercial_pipe: 'Pipe commercial',
          production_of_boats: 'Production'
        },
      },
    }
  }
}
