var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class LoginComponent {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ApplicationDataSA.
     * @param {boolean} published_mode
     * @memberof Class_ApplicationDataSA
     */
    constructor(published_mode, options = {}) {
        // PROTECTED ATTRIBUTES ===============================================================
        this._has_account = false; // token when user is connected with an account
        this._has_licence = false;
        this._ok_to_check_account = true;
        this._ok_to_check_account_timeout = null;
        // Default config on creation
        this._has_licence = false;
    }
    checkTokens(setUpdate_1) {
        return __awaiter(this, arguments, void 0, function* (setUpdate, force = false) {
            if (this._ok_to_check_account || force) {
                // Default token
                this._has_account = false;
                //this._has_sankey_plus = false
                // Update token
                yield fetch(window.location.origin + '/auth/connected')
                    .then((response) => {
                    // Update booleans
                    if (response.ok)
                        this._has_account = true;
                })
                    .then(() => {
                    // Update account token
                    // Check licenses
                    if (this._has_account)
                        return fetch(window.location.origin + '/auth/license')
                            .then((response) => {
                            let has_license = false;
                            if (response.ok)
                                has_license = true;
                            return has_license;
                        })
                            .then((has_license) => {
                            this._has_licence = has_license;
                        });
                })
                    .then(() => {
                    setUpdate.current();
                });
                // Cannot check for given time
                this._ok_to_check_account = false;
                if (this._ok_to_check_account_timeout)
                    clearTimeout(this._ok_to_check_account_timeout);
                this._ok_to_check_account_timeout = setTimeout(() => { this._ok_to_check_account = true; }, 1800);
            }
        });
    }
    unsetTokens() {
        this._has_account = false;
        this._has_licence = false;
        this._ok_to_check_account = true;
    }
    get has_account() { return this._has_account; }
    get has_licence() { return this._has_licence; }
}
let _loginComponent = undefined;
export const loginComponent = () => {
    if (!_loginComponent) {
        _loginComponent = new LoginComponent(false, {});
    }
    return _loginComponent;
};
