var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as d3 from 'd3';
import { activateLicenseToken, app_name_opensankeyplus, } from '../Register/RegisterFunctions';
const resetLogs = () => {
    d3.select('.LogInfo').selectAll('*').remove();
    d3.select('.LogError').selectAll('*').remove();
};
export const logInfo = (info) => {
    d3.select('.LogInfo').append('p').text(info);
};
export const logError = (err) => {
    d3.select('.LogError').append('p').text(err);
};
// Activate license Tokens if licenses are valid
export function activateLicensesTokens(loginComponent, setUpdate) {
    // // Check AFM license
    // activateLicenseToken(
    //   app_name_sankeysuite,
    //   '/user/infos/legacy/license_sankeysuite',
    //   () => {
    //     set_update(!update)
    //   }
    // )
    // Check OpenSankey+ licence
    activateLicenseToken(app_name_opensankeyplus, '/user/infos/legacy/license_opensankeyplus', () => { loginComponent().checkTokens(setUpdate); });
    // // Check if has dev acc
    // fetch('/user/infos/legacy/is_developer',)
    //   .then(response => {
    //     if (response.ok) {
    //       return response.json()
    //     } else {
    //       return Promise.reject(response)
    //     }
    //   }).then(data => {
    //     if (data.is_dev) {
    //       sessionStorage.setItem('SankeyDev', LZString.compress(JSON.stringify(true)))
    //     }
    //     //set_update(!update)
    //   })
}
// Check if login if valid
export function loginUser(t, loginComponent, setUpdate, credentials, callbackSuccess) {
    return __awaiter(this, void 0, void 0, function* () {
        // Remove all errors from screen
        resetLogs();
        // Fetch Login
        const path = window.location.origin;
        const url = path + '/auth/login';
        yield fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                logError(t('Login.err_server'));
                return Promise.reject(response);
            }
        })
            .then(response => {
            if (response['message'] === 'ok') {
                logInfo(t('Login.msg.ok'));
                return;
            }
            else {
                logError(t('Login.msg.' + response['message']));
                return Promise.reject(response);
            }
        })
            .then(() => {
            return loginComponent().checkTokens(setUpdate, true);
        })
            .then(() => {
            callbackSuccess();
        })
            .catch(error => {
            console.error('Error in loginUser - ' + error.toString());
        });
    });
}
//Logout
export function loginOut(loginComponent, setUpdate, callback = () => { }) {
    // LogOut on server
    const path = window.location.origin;
    const url = path + '/auth/logout';
    return fetch(url)
        .then(() => {
        // Check that we are effectivly disconnected
        return loginComponent().checkTokens(setUpdate, true);
    })
        .then(callback);
}
/**
 * Send API request to trigger reset user password
 * @export
 * @param {Class_ApplicationDataSA} new_data_app
 * @param {{
 *     email: string,
 *     lang: string,
 *   }} infos
 * @param {() => void} callbackSuccess
 * @return {*}
 */
export function triggerPasswordReset(t, infos, callbackSuccess) {
    return __awaiter(this, void 0, void 0, function* () {
        // Remove all errors from screen
        resetLogs();
        // Fetch Login
        const path = window.location.origin;
        const url = path + '/auth/forgot_pw';
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infos)
        })
            .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                logError(t('Login.forgot.msg.err_server'));
                return Promise.reject(response);
            }
        })
            .then(data => {
            if (data['user_is_authenticated'] === true) {
                logError(t('Login.forgot.msg.err_user_already_connected'));
            }
            else if (data['user_exists'] === false) {
                logError(t('Login.forgot.msg.err_user_inexistant'));
            }
            else {
                logInfo(t('Login.forgot.msg.mail_sent'));
                setTimeout(callbackSuccess, 3000);
            }
        })
            .catch(error => {
            console.error('Error in triggerPasswordReset - ' + error.toString());
        });
    });
}
/**
 * Send API request to effectively change password in database
 * based on given request token
 *
 * @export
 * @param {Class_ApplicationDataSA} new_data_app
 * @param {{
 *     token: string,
 *     password: string,
 *     lang: string,
 *   }} infos
 * @param {() => void} callbackSuccess
 * @return {*}
 */
export function applyPasswordReset(t, token, infos, callbackSuccess) {
    return __awaiter(this, void 0, void 0, function* () {
        // Remove all errors from screen
        d3.select('.LogError').selectAll('*').remove();
        // Fetch Login
        const path = window.location.origin;
        const url = path + '/auth/reset_pw/' + token;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(infos)
        })
            .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                d3.select('.LogError').append('p').text(t('Login.forgot.msg.err_server'));
                return Promise.reject(response);
            }
        })
            .then(data => {
            if (data['user_is_authenticated'] === true) {
                d3.select('.LogError').append('p').text(t('Login.forgot.msg.err_user_already_connected'));
                return;
            }
            if (data['passwd_is_updated'] === true) {
                d3.select('.LogInfo').append('p').text(t('Login.forgot.msg.ok'));
                setTimeout(callbackSuccess, 3000);
                return;
            }
            d3.select('.LogError').append('p').text(t('Login.forgot.msg.err_token_expire'));
        })
            .catch(error => {
            console.error('Error in applyPasswordReset - ' + error.toString());
        });
    });
}
