import { useRef } from 'react';
import { Class_MenuConfigMFA } from '../deps/MFASankey/types/MenuConfigMFA';
export class Class_MenuConfigSA extends Class_MenuConfigMFA {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_MenuConfigOSP.
     * @memberof Class_MenuConfigOSP
     */
    constructor() {
        // Init parent class
        super();
        // New attributes
        this._ref_to_additional_menus_updater = useRef(() => null);
        this._dict_setter_show_dialog_SA = {
            ref_setter_show_modal_sankeytheque: useRef(() => null)
        };
    }
    // PUBLIC METHODS ====================================================================
    updateComponentsRelatedToSA() {
        this._ref_to_additional_menus_updater.current();
    }
    updateAllMenuComponents() {
        super.updateAllMenuComponents();
        this.updateComponentsRelatedToSA();
    }
    // GETTERS / SETTERS ==================================================================
    get ref_to_additional_menus_updater() { return this._ref_to_additional_menus_updater; }
    get dict_setter_show_dialog_SA() { return this._dict_setter_show_dialog_SA; }
}
