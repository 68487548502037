import * as d3 from 'd3';
import React, { useState, useRef } from 'react';
import { FaPlus } from 'react-icons/fa';
import { Box, Card, CardBody, Divider, Heading, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import SankeyListIcons from '../../icons/lib_of_icons.json';
export const ModalSelectionIconsOSP = ({ new_data_plus }) => {
    const list_nodes_selected = new_data_plus.drawing_area.selected_nodes_list;
    const { t } = new_data_plus;
    const imported_icon = localStorage.getItem('icon_imported');
    const init_imported_svg = imported_icon != null && imported_icon !== '' ? JSON.parse(imported_icon) : {};
    const [filter_name, set_filter_name] = useState('');
    const _load_svg = useRef(null);
    const import_svg = useRef(init_imported_svg);
    const [s_show_modal, sShowModal] = useState(false);
    const [forceUpdate, setForceUpdate] = useState(false);
    new_data_plus.menu_configuration.dict_setter_show_dialog_plus.ref_setter_show_modal_import_icons.current = sShowModal;
    const isAllIconVisible = () => {
        let selected_icon = list_nodes_selected.length > 0 ? list_nodes_selected[0].iconName : '';
        list_nodes_selected.map(d => selected_icon = (d.iconName === selected_icon) ? selected_icon : '');
        return selected_icon;
    };
    const allSelectedNodeHasSameicon = isAllIconVisible();
    list_nodes_selected.length > 0 ? list_nodes_selected[0].iconName : 'None';
    // Functions we can undo ====================================================
    const updateNodeIcon = (iconName) => {
        const dict_old_value = {};
        list_nodes_selected.forEach(n => {
            dict_old_value[n.id] = [n.iconName, n.iconColor, n.iconViewBox];
        });
        const _updateNodeIcon = () => {
            list_nodes_selected.forEach(n => {
                n.iconName = iconName;
                if (!n.iconColor)
                    n.iconColor = '#000000';
                delete n.iconViewBox;
                n.draw();
            });
            setForceUpdate(!forceUpdate);
        };
        const inv_updateNodeIcon = () => {
            list_nodes_selected.forEach(n => {
                n.iconName = dict_old_value[n.id][0];
                n.iconColor = dict_old_value[n.id][1];
                n.iconViewBox = dict_old_value[n.id][2];
                n.draw();
            });
            setForceUpdate(!forceUpdate);
        };
        // Save undo/redo in data history
        new_data_plus.history.saveUndo(inv_updateNodeIcon);
        new_data_plus.history.saveRedo(_updateNodeIcon);
        // Execute original attr mutation
        _updateNodeIcon();
    };
    const updateNodeIconImported = (ki) => {
        const dict_old_value = {};
        list_nodes_selected.forEach(n => {
            dict_old_value[n.id] = [n.iconName, n.iconColor, n.iconViewBox];
        });
        const _updateNodeIconImported = () => {
            list_nodes_selected.forEach(n => {
                n.iconName = 'icon_imported_' + ki;
                n.iconViewBox = import_svg.current[ki].Vb;
                n.iconColor = '#000000';
                n.draw();
            });
            setForceUpdate(!forceUpdate);
        };
        const inv_updateNodeIconImported = () => {
            list_nodes_selected.forEach(n => {
                n.iconName = dict_old_value[n.id][0];
                n.iconColor = dict_old_value[n.id][1];
                n.iconViewBox = dict_old_value[n.id][2];
                n.draw();
            });
            setForceUpdate(!forceUpdate);
        };
        // Save undo/redo in data history
        new_data_plus.history.saveUndo(inv_updateNodeIconImported);
        new_data_plus.history.saveRedo(_updateNodeIconImported);
        // Execute original attr mutation
        _updateNodeIconImported();
    };
    // Create object containing list of card elements regrouped by the icon themes
    const tuto_sub_nav = {};
    {
        Object.keys(SankeyListIcons).map((cki) => {
            const ki = cki;
            tuto_sub_nav[ki] = React.createElement(React.Fragment, null, Object.entries(SankeyListIcons[ki]).filter(ic => {
                return filter_name === '' ? true : t(ki + '.' + ic[0]).includes(filter_name);
            }).sort(([a,], [b,]) => (t(ki + '.' + a) > t(ki + '.' + b)) ? 1 : ((t(ki + '.' + b) > t(ki + '.' + a)) ? -1 : 0)).map((icon, i) => {
                // icon[0]:Name of the icon
                // icon[1]:Path of the icon
                const NameIcon = ki + '_' + icon[0];
                return React.createElement(Card, { key: 'card_' + icon[0] + '_' + i, variant: allSelectedNodeHasSameicon === NameIcon ? 'card_icon_selected' : 'card_icon_not_selected', onClick: () => {
                        new_data_plus.drawing_area.sankey.icon_catalog[NameIcon] = icon[1];
                        updateNodeIcon(NameIcon);
                        sShowModal(false);
                    } },
                    React.createElement(CardBody, null,
                        React.createElement(Heading, null, t(ki + '.' + icon[0])),
                        React.createElement(Divider, null),
                        React.createElement("svg", { viewBox: '0 0 1000 1000', width: 50, height: 50 },
                            React.createElement("g", null,
                                React.createElement("path", { fill: 'black', d: icon[1] })))));
            }));
        });
    }
    // File reader that can process multiple files
    // If the svg contains multiple path, we concat all of them into one big path
    // The icon name is the file name
    const file_import = React.createElement(Input, { accept: '.svg', type: "file", multiple: true, ref: _load_svg, style: { display: 'none' }, onChange: (evt) => {
            const files = evt.target.files;
            for (const i in files) {
                const reader = new FileReader();
                reader.onload = (() => {
                    return (e) => {
                        const result = String(e.target.result);
                        const placeholder = document.createElement('div');
                        placeholder.innerHTML = result;
                        const path = d3.select(placeholder).selectAll('path');
                        const attr_d = path.nodes().map(path_to_concat => d3.select(path_to_concat).attr('d')).join(' ');
                        import_svg.current[(files[i].name).replace('.svg', '')] = {
                            path: attr_d,
                            Vb: d3.select(placeholder).select('svg').attr('viewBox')
                        };
                        setForceUpdate(!forceUpdate);
                    };
                })();
                if (Number(i) === files.length - 1) {
                    reader.onloadend = (() => {
                        return () => {
                            // import_svg.current=import_svg
                            localStorage.setItem('icon_imported', JSON.stringify(import_svg.current));
                        };
                    })();
                }
                // Permet d'executer la transformation des blob en vues tout en evitant la var length
                //   files : {0:Blob,1:Blob,2:...,n:Blob, length:n-1}
                if (!isNaN(+i)) {
                    reader.readAsText(files[i]);
                }
            }
        } });
    // Card used as a button to open a filereader and import a svg
    const card_add_svg = React.createElement(Card, { variant: 'card_import_icon', onClick: () => {
            if (_load_svg.current) {
                _load_svg.current.name = '';
                _load_svg.current.click();
            }
        } },
        React.createElement(CardBody, null,
            React.createElement(Heading, null, t('Import')),
            React.createElement(Divider, null),
            React.createElement(FaPlus, { style: { width: '5em', height: '5em' } })));
    // List of all imported svg icon
    // WARNING : Those icon disappear whe nwe reload the application (but the icon are still present in the catalog), so
    const card_imported = Object.keys(import_svg.current).sort(([a,], [b,]) => (a > b) ? 1 : ((b > a) ? -1 : 0)).map((ki, i) => {
        return React.createElement(Card, { key: 'card_icon_' + i, variant: allSelectedNodeHasSameicon === 'icon_imported_' + ki ? 'card_icon_selected' : 'card_icon_not_selected', onClick: () => {
                new_data_plus.drawing_area.sankey.icon_catalog['icon_imported_' + ki] = import_svg.current[ki].path;
                updateNodeIconImported(ki);
                sShowModal(false);
            } },
            React.createElement(CardBody, null,
                React.createElement(Heading, null, ki),
                React.createElement(Divider, null),
                React.createElement("svg", { viewBox: import_svg.current[ki].Vb, width: 50, height: 50 },
                    React.createElement("g", null,
                        React.createElement("path", { fill: 'black', d: import_svg.current[ki].path })))));
    });
    tuto_sub_nav['import'] = React.createElement(React.Fragment, null,
        card_imported,
        card_add_svg);
    return React.createElement(React.Fragment, null,
        React.createElement(Modal, { isOpen: s_show_modal, onClose: () => sShowModal(false) },
            React.createElement(ModalContent, { maxWidth: 'inherit' },
                React.createElement(ModalHeader, null, t(('Menu.import_icon'))),
                React.createElement(ModalCloseButton, null),
                React.createElement(ModalBody, null,
                    React.createElement(Tabs, { orientation: 'vertical', align: 'start', variant: 'tabs_variant_template', height: '100%' },
                        React.createElement(TabList, null, Object.keys(tuto_sub_nav).map((m, i) => {
                            return React.createElement(Tab, { key: 'tab_icon_catalog_' + i },
                                " ",
                                t(m + '.' + m));
                        })),
                        React.createElement(TabPanels, null, Object.keys(tuto_sub_nav).map((modale_sub_icon, i) => {
                            return React.createElement(TabPanel, { key: 'panel_icon_catalog_' + i },
                                React.createElement(Box, { display: 'grid', gridAutoFlow: 'row', gridRowGap: '1rem', height: '100%' },
                                    modale_sub_icon !== 'import' ?
                                        React.createElement(Box, { as: 'span', layerStyle: 'menuconfigpanel_row_2cols' },
                                            React.createElement(Box, { layerStyle: 'menuconfigpanel_option_name' }, t('Menu.filter_by_name')),
                                            React.createElement(Input, { placeholder: 'start typing to filter displayed icon', variant: 'menuconfigpanel_option_input', value: filter_name, onChange: evt => set_filter_name(evt.target.value) })) :
                                        React.createElement(React.Fragment, null),
                                    React.createElement(Box, { display: "block", overflow: 'scroll', height: '100%' },
                                        React.createElement(Box, { layerStyle: 'options_cards' }, tuto_sub_nav[modale_sub_icon]))));
                        })))))),
        file_import);
};
export default ModalSelectionIconsOSP;
