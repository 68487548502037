var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaPowerOff } from 'react-icons/fa';
import { Box, Button, Card, CardBody, CardHeader, FormControl, Image, Input, InputGroup, InputLeftAddon, InputRightElement, Spinner } from '@chakra-ui/react';
import { loginOut, loginUser } from './LoginFunctions';
// Login
export const Login = ({ t, logo, returnToApp, loginComponent, setUpdate, noLicenceAccountRequired }) => {
    // States
    const [on_wait, setOnWait] = useState(false);
    const [email, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [show_password, setShowPassword] = useState(false);
    const [remember] = useState(false);
    const state = {
        button: ''
    };
    // Login button ref
    const ref_login_btn = useRef(null);
    // Initialise navigation function
    const navigate = useNavigate();
    // Handler : Si demande de connection
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        if (state.button === 'login') {
            setOnWait(true);
            yield loginUser(t, loginComponent, setUpdate, {
                email,
                password,
                remember
            }, () => {
                returnToApp(navigate);
            })
                .then(() => setOnWait(false));
        }
        if (state.button === 'forgot') {
            navigate('/login/forgot');
        }
    });
    let template = 'minmax(7vw, 150px) auto 11rem 11rem';
    if (noLicenceAccountRequired) {
        template = 'minmax(7vw, 150px) auto 11rem';
    }
    // React output
    return (React.createElement("div", null,
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: template },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'center' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, null),
                !noLicenceAccountRequired ? React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => returnToApp(navigate) }, t('UserNav.to_app')) : React.createElement(React.Fragment, null),
                React.createElement(Button, { variant: 'btn_lone_navigation_secondary', onClick: () => navigate('/register') }, t('UserNav.to_reg')))),
        React.createElement("div", { className: "login-wrapper" },
            React.createElement(Card, { variant: 'card_register', width: '33vw' },
                React.createElement(CardHeader, { style: { 'textAlign': 'center' } }, t('Login.title')),
                React.createElement(CardBody, null,
                    React.createElement(FormControl, null,
                        React.createElement(InputGroup, { variant: 'register_input' },
                            React.createElement(InputLeftAddon, null, t('Login.id.label')),
                            React.createElement(Input, { isRequired: true, type: 'text', placeholder: t('Login.id.placeholder'), onChange: e => setUserName(e.target.value) }))),
                    React.createElement(FormControl, null,
                        React.createElement(InputGroup, { variant: 'register_input' },
                            React.createElement(InputLeftAddon, null, t('Login.pwd.label')),
                            React.createElement(Input, { isRequired: true, type: show_password ? 'text' : 'password', placeholder: t('Login.pwd.placeholder'), onChange: e => setPassword(e.target.value), onBlur: () => { var _a; (_a = ref_login_btn.current) === null || _a === void 0 ? void 0 : _a.click(); } }),
                            React.createElement(InputRightElement, { width: '4.5rem', marginRight: '0.25em' },
                                React.createElement(Button, { h: '1.75rem', size: 'sm', border: '0px', bg: 'gray.50', onClick: () => setShowPassword(!show_password) }, show_password ? t('Login.pwd.hide') : t('Login.pwd.show'))))),
                    React.createElement(Box, { display: 'grid', gridAutoFlow: 'row', gridRowGap: '0,25rem' },
                        React.createElement(Button, { ref: ref_login_btn, variant: 'btn_lone_navigation_tertiary', type: "submit", isDisabled: (on_wait ||
                                (password.length === 0) ||
                                (email.length === 0)), onClick: () => {
                                (state.button = 'login');
                                handleSubmit();
                            } }, on_wait ?
                            React.createElement(Spinner, null) :
                            t('Login.con')),
                        React.createElement(Button, { variant: 'btn_lone_navigation_tertiary_negative', type: "submit", onClick: () => {
                                (state.button = 'forgot');
                                handleSubmit();
                            } }, t('Login.forgot.ask'))),
                    React.createElement("div", { className: 'LogError', style: {
                            'color': 'red',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }),
                    React.createElement("div", { className: 'LogInfo', style: {
                            'color': 'green',
                            'justifySelf': 'center',
                            'textAlign': 'center'
                        } }))))));
};
export const LoginOutButton = ({ t, logo, returnToApp, loginComponent, setUpdate }) => {
    const navigate = useNavigate();
    const [on_wait, setOnWait] = useState(false);
    return React.createElement(Button, { variant: 'menutop_button_logout', disabled: on_wait, onClick: () => {
            setOnWait(true);
            loginOut(loginComponent, setUpdate, () => {
                setOnWait(false);
                returnToApp(navigate);
            });
        } }, on_wait ?
        React.createElement(Spinner, null) :
        React.createElement(FaPowerOff, null));
};
