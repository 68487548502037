// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux
// ==================================================================================================
// OpenSankey imports
import { default_main_sankey_id, default_style_id, getJSONFromJSON } from '../deps/OpenSankey/types/Utils';
import { ClassAbstract_SankeyOSP } from './AbstractOSP';
import { Class_ContainerElement } from './FreeLabel';
// CLASS SANKEY PLUS *********************************************************************
/**
 * Contains all necessary elements to draw a Sankey
 *
 * @export
 * @class ClassTemplate_SankeyOSP
 */
export class ClassTemplate_SankeyOSP extends ClassAbstract_SankeyOSP {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of ClassTemplate_SankeyOSP.
     * @param {Type_GenericDrawingArea} drawing_area
     * @memberof ClassTemplate_SankeyOSP
     */
    constructor(drawing_area, menu_config, id = default_main_sankey_id) {
        // Heritance
        super(drawing_area, menu_config, id);
        /**
         * Contains dict of Free Labels elements
         * @protected
         * @type {{ [_: string]: Class_ContainerElement<Type_GenericDrawingArea, ClassTemplate_SankeyOSP<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>> }}
         * @memberof ClassTemplate_SankeyOSP
         */
        this._containers = {};
        /**
         * Allows to toggle Sankey visibility
         * @protected
         * @type {boolean}
         * @memberof ClassTemplate_SankeyOSP
         */
        this._is_visible = true;
        // PRIVATE ATTRIBUTES =================================================================
        this._icon_catalog = {};
        // Overrides
        this._menu_config = menu_config;
        // New attributes
        this.name = this.id; // Default name = id
        this._containers = {};
        this._icon_catalog = {};
    }
    // CLEANING METHODS ===================================================================
    delete() {
        super.delete();
        // Properly delete containers
        this.containers_list.forEach(container => container.delete());
        this._containers = {};
    }
    // COPY METHODS =======================================================================
    /**
     * Copy all attributes values from given sankey to copy
     * @param {ClassTemplate_SankeyOSP<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>} sankey_to_copy
     * @memberof ClassTemplate_SankeyOSP
     */
    copyFrom(sankey_to_copy) {
        // Herited methods
        super.copyFrom(sankey_to_copy);
        // Add container copy
        Object.entries(sankey_to_copy._containers)
            .forEach(([idx, container_to_copy]) => {
            this.addNewFreeLabel(idx)
                .copyFrom(container_to_copy);
        });
    }
    /**
     * Update somes attributes values from a given other sankey
     * @param {ClassTemplate_SankeyOSP<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>} other_sankey
     * @param {string[]} mode
     * @memberof ClassTemplate_SankeyOSP
     */
    updateFrom(other_sankey, mode) {
        // Call inherited method
        super.updateFrom(other_sankey, mode);
        // Add specifities from OSP
        const all = mode.includes('*');
        // Update Containers
        const list_curr_container = this.containers_list;
        const list_new_container = other_sankey.containers_list;
        if (mode.includes('freeLabels') || all) {
            // Add new container present in new but not current
            list_new_container.filter(new_cont => !list_curr_container.map(curr_cont => curr_cont.id).includes(new_cont.id))
                .forEach(cont => {
                this.addNewFreeLabel(cont.id);
                this.containers_dict[cont.id].copyFrom(cont);
            });
            // Delete container present in current but not new
            list_curr_container.filter(curr_cont => !list_new_container.map(new_cont => new_cont.id).includes(curr_cont.id))
                .forEach(cont => {
                this.deleteContainer(cont);
            });
            // Update container in current that are also in new
            list_new_container.filter(new_cont => list_curr_container.map(curr_cont => curr_cont.id).includes(new_cont.id))
                .forEach(cont => {
                this.containers_dict[cont.id].copyFrom(cont);
            });
        }
        // Update icon catalog
        if (mode.includes('icon_catalog') || all) {
            Object.entries(other_sankey.icon_catalog).filter(icon => icon[0] && icon[1]).forEach(icon => {
                this.icon_catalog[icon[0]] = icon[1];
            });
        }
    }
    // SAVING METHODS ====================================================================
    /**
     * Setting value of sankey and substructur from JSON
     *
     * @param {boolean} [only_visible_elements]
     * @param {boolean} [with_values]
     * @return {*}  {Type_JSON}
     * @memberof ClassTemplate_SankeyOSP
     */
    toJSON(only_visible_elements, with_values) {
        const json_entry = super.toJSON(only_visible_elements, with_values);
        // Class container
        const json_object_labels = {};
        json_entry['labels'] = json_object_labels;
        this.containers_list.forEach(obj => {
            json_object_labels[obj.id] = obj.toJSON();
        });
        // Icon catalog
        json_entry['icon_catalog'] = this._icon_catalog;
        return json_entry;
    }
    /**
     * Extract sankey as a JSON struct
     *
     * @param {Type_JSON} json_object
     * @param {boolean} [match_and_update]
     * @memberof ClassTemplate_SankeyOSP
     */
    fromJSON(json_object, match_and_update) {
        super.fromJSON(json_object, match_and_update);
        // Class container
        const json_container_object = getJSONFromJSON(json_object, 'labels', {});
        Object.entries(json_container_object)
            .forEach(([_, container_json]) => {
            const container = this.addNewFreeLabel(_);
            // Set container value to node from JSON
            container.fromJSON(container_json);
        });
        // Icon catalog
        this._icon_catalog = getJSONFromJSON(json_object, 'icon_catalog', this._icon_catalog);
    }
    // PUBLIC METHODS =====================================================================
    // New --------------------------------------------------------------------------------
    /**
     * Add a given zdt to Sankey
     * @param {Class_ContainerElement<Type_GenericDrawingArea, ClassTemplate_SankeyOSP<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>>} node
     * @memberof ClassTemplate_SankeyOSP
     */
    _addLabel(zdt) {
        this._containers[zdt.id] = zdt;
    }
    /**
     * Create and add a node for this Sankey
     * @param {string} id
     * @param {string} name
     * @return {Class_Node}
     * @memberof ClassTemplate_SankeyOSP
     */
    addNewFreeLabel(id) {
        if (!this._containers[id]) {
            // Create node
            const zdt = new Class_ContainerElement(id, this._menu_config, this.drawing_area);
            // Set node to default position
            zdt.initDefaultPosXY();
            // Update registry of nodes
            this._addLabel(zdt);
            return zdt;
        }
        else {
            return this.addNewFreeLabel(id + '_0');
        }
    }
    /**
     * Create and add a node for this Sankey with default name
     * @return {*}
     * @memberof ClassTemplate_SankeyOSP
     */
    addNewDefaultFreeLabel() {
        const n = String(Object.values(this._containers).length);
        const id = 'free_label' + n;
        return this.addNewFreeLabel(id);
    }
    /**
     * Permanently delete selected nodes
     * @memberof Type_GenericDrawingArea
     */
    deleteSelectedFreeLabels() {
        // Get copy of selected nodes
        const selected_labels = this.drawing_area.selected_containers_list;
        // Delete each one of them
        selected_labels.forEach(selected_label => { this.deleteContainer(selected_label); });
        // Then let garbage collector do the rest...
    }
    /**
   * Delete a given zdt from Sankey -> zdt may still exist somewhere
   * @param {Class_ContainerElement<Type_GenericDrawingArea, ClassTemplate_SankeyOSP<Type_GenericDrawingArea, Type_GenericNodeElement, Type_GenericLinkElement>>} zdt
   * @memberof ClassTemplate_SankeyOSP
   */
    deleteContainer(zdt) {
        if (this._containers[zdt.id] !== undefined) {
            // Delete node in sankey
            const _ = this._containers[zdt.id];
            delete this._containers[zdt.id];
            _.delete();
        }
    }
    /**
     * Return the path of the icon, if it doesn't exist return an empty string
     *
     * @param {string} id_icon
     * @return {*}
     * @memberof ClassTemplate_SankeyOSP
     */
    getIconFromCatalog(id_icon) {
        const icon = this.icon_catalog[id_icon];
        if (icon !== undefined && icon !== null) {
            return icon;
        }
        return '';
    }
    // GETTERS / SETTERS ==================================================================
    // Sankey visibility - for views
    setVisible() { this._is_visible = true; }
    setInvisible() { this._is_visible = false; }
    toggleVisibility() { this._is_visible = !this._is_visible; }
    get is_visible() { return this._is_visible; }
    // Free labels
    get containers_dict() { return this._containers; }
    get containers_list() { return Object.values(this._containers); }
    get containers_list_sorted() { return this.containers_list.sort((a, b) => (a.title > b.title) ? 1 : ((b.title > a.title) ? -1 : 0)); }
    get visible_containers_list() {
        return this.containers_list.filter(zdt => zdt.is_visible);
    }
    // Icons
    get icon_catalog() { return this._icon_catalog; }
    set icon_catalog(value) { this._icon_catalog = value; }
    // Links styles
    get default_link_style() { return this._link_styles[default_style_id]; }
    get link_styles_dict() {
        return this._link_styles;
    }
    // Nodes styles
    get default_node_style() { return this._node_styles[default_style_id]; }
    get node_styles_dict() {
        return this._node_styles;
    }
}
