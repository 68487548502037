// Standard libs
import React, { useState } from 'react';
// OpenSankey libs
import { ModalWelcome, ModalWelcomeContent } from '../../deps/OpenSankey/components/welcome/ModalWelcome';
import { Box, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
const ShortcutsOSP = (new_data_plus, page_content) => {
    const { t } = new_data_plus;
    page_content['rc'] = React.createElement(Box, { display: "block", overflowY: 'scroll', overflowX: 'hidden', height: '100%', width: '100%' },
        React.createElement(Box, { display: 'grid', gridTemplateColumns: '50% 50%', gridColumnGap: '0.25rem', width: '100%', height: '100%', justifySelf: 'center' },
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_select'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_shift_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_shift_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_shift_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_shift_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_cf'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cs_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cs'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag_bold')),
                        React.createElement(Td, null, t('Menu.rcc_click_and_drag'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_cdn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_cdn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ad_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ad'))))),
            React.createElement(Table, { variant: 'table_welcome_buttons' },
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_edi'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_cn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_cn'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_ds_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_ds'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_e_dn_bold')),
                        React.createElement(Td, null, t('Menu.rcc_e_dn')))),
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_OSP'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_osp_cs_bold')),
                        React.createElement(Td, null, t('Menu.rcc_osp_cs'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt_bold')),
                        React.createElement(Td, null, t('Menu.rcc_osp_ctrl_czdt'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_F7_bold')),
                        React.createElement(Td, null, t('Menu.rcc_F7'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_F8_bold')),
                        React.createElement(Td, null, t('Menu.rcc_F8'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_F9_bold')),
                        React.createElement(Td, null, t('Menu.rcc_F9')))),
                React.createElement(Thead, null,
                    React.createElement(Th, { colSpan: 2 }, t('Menu.rcc_titre_autre'))),
                React.createElement(Tbody, null,
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_s_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_s'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_fc_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_fc'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_dbm_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_dbm'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_a_ech_bold')),
                        React.createElement(Td, null, t('Menu.rcc_a_ech'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_scrll'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_z_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_z'))),
                    React.createElement(Tr, null,
                        React.createElement(Td, null, t('Menu.rcc_ctrl_y_bold')),
                        React.createElement(Td, null, t('Menu.rcc_ctrl_y')))))));
};
export const ModalWelcomeBuilderOSP = ({ new_data }) => {
    const [, setCount] = useState(0);
    new_data.menu_configuration.dict_setter_show_dialog.ref_setter_modal_welcome_active_page.current = () => setCount(a => a + 1);
    const [page_links, page_content] = ModalWelcomeContent(new_data);
    ShortcutsOSP(new_data, page_content);
    return React.createElement(ModalWelcome, { new_data: new_data, external_pagination: page_links, external_content: page_content });
};
