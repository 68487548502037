var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as d3 from 'd3';
import LZString from 'lz-string';
import i18next from 'i18next';
export const app_name_opensankeyplus = 'OpenSankey+';
export const app_name_sankeysuite = 'SankeySuite';
const resetLogs = () => {
    d3.select('.LogInfo').selectAll('*').remove();
    d3.select('.LogError').selectAll('*').remove();
};
export const logInfo = (info) => {
    d3.select('.LogInfo').append('p').text(info);
};
export const logError = (err) => {
    d3.select('.LogError').append('p').text(err);
};
// Check Licence and register account if everything is Ok
export function userSignUp(email, password, firstname, lastname, callback) {
    return __awaiter(this, void 0, void 0, function* () {
        resetLogs();
        fetch(window.location.origin + '/auth/signup/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email: email,
                password: password,
                firstname: firstname,
                lastname: lastname,
                lang: i18next.language
            })
        })
            .then((response) => {
            if (response.ok)
                return response.json();
            else
                logError(i18next.t('Register.account.msg.nok'));
            return Promise.reject(response);
        })
            .then((response) => {
            if (response) {
                if (response['message'] === 'ok') {
                    logInfo(i18next.t('Register.account.msg.ok'));
                    callback(true);
                }
                else {
                    logError(i18next.t('Register.account.msg.' + response['message']));
                    callback(false);
                }
            }
        })
            .catch(error => {
            console.error('Error in userSignUp - ' + error.toString());
        });
    });
}
// Check Licence and register account if everything is Ok
export function userValidate(token, loginComponent, navigate, setUpdate) {
    return __awaiter(this, void 0, void 0, function* () {
        resetLogs();
        // Enregistrement dans la base de donnée utilisateur
        fetch(window.location.origin + '/auth/signup/confirm', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token: token
            })
        })
            .then((response) => {
            if (response.ok)
                return response.json();
            else {
                logError(i18next.t('Register.validation.msg.nok'));
                return Promise.reject(response);
            }
        })
            .then((response) => {
            logInfo(i18next.t('Register.validation.msg.' + response['message']));
        })
            .then(() => {
            return loginComponent().checkTokens(setUpdate, true);
        })
            .then(() => {
            let next_page;
            if (loginComponent().has_account) {
                logInfo(i18next.t('Register.validation.msg.redirect'));
                next_page = '/license/checkout';
            }
            else {
                next_page = '/login';
            }
            setTimeout(() => navigate(next_page), 3000);
        })
            .catch(error => {
            console.error('Error in userValidate - ' + error.toString());
        });
    });
}
// Check a license status
function checkLicense(app_name, license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        // Get server api url
        const path = window.location.origin;
        const url = path + '/api/edd_license';
        // use server as proxy to fetch informations
        // -> Avoid "Same-Origin" problem with CORS
        const data = fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'action': 'check_license',
                'app_name': app_name,
                'license_id': license_id
            })
        })
            .then(response => response.json())
            .then(response_json => {
            return response_json;
        });
        return data;
    });
}
export function checkLicenseOpenOSP(license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield checkLicense(app_name_opensankeyplus, license_id)
            .then(_ => {
            return _;
        });
        return data;
    });
}
export function checkLicenseSankeySuite(license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        const data = yield checkLicense(app_name_sankeysuite, license_id)
            .then(_ => {
            return _;
        });
        return data;
    });
}
// Activate a new license
function activateLicense(app_name, license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        // Get server api url
        const path = window.location.origin;
        const url = path + '/api/edd_license';
        // use server as proxy to fetch informations
        // -> Avoid "Same-Origin" problem with CORS
        const data = fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'action': 'activate_license',
                'app_name': app_name,
                'license_id': license_id
            })
        })
            .then(response => response.json())
            .then(response_json => {
            return response_json;
        });
        return data;
    });
}
// Register a new license
function registerNewLicense(app_name, license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            checkLicense(app_name, license_id)
                .then(data_edd_check => {
                if (data_edd_check.success) {
                    activateLicense(app_name, license_id)
                        .then(data_edd_activate => {
                        if (data_edd_activate.success) {
                            resolve('Activation succeeded');
                        }
                        else {
                            reject('Failed to activate licence');
                        }
                    })
                        .catch(error => {
                        // Erreur activate license
                        reject('Error in activate licence - ' + error.toString());
                    });
                }
                else {
                    reject('Failed to check licence');
                }
            })
                .catch(error => {
                // Erreur fetch license
                reject('Error in check licence - ' + error.toString());
            });
        });
    });
}
export function registerNewLicenseOpenOSP(license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            registerNewLicense(app_name_opensankeyplus, license_id)
                .then(_ => {
                resolve(_);
            })
                .catch(error => {
                reject(error);
            });
        });
    });
}
export function registerNewLicenseSankeySuite(license_id) {
    return __awaiter(this, void 0, void 0, function* () {
        return new Promise((resolve, reject) => {
            registerNewLicense(app_name_sankeysuite, license_id)
                .then(_ => {
                resolve(_);
            })
                .catch(error => {
                reject(error);
            });
        });
    });
}
export function activateLicenseToken(app_name, req_url, setLicenseToken) {
    return __awaiter(this, void 0, void 0, function* () {
        // Check AFM license
        fetch(req_url)
            .then(response => {
            if (response.ok) {
                return response.json();
            }
            else {
                return Promise.reject(response);
            }
        }).then(data => {
            checkLicense(app_name, data.license_id).then(data_edd => {
                if (data_edd.success && data_edd.license === 'valid') {
                    sessionStorage.setItem(app_name, LZString.compress(JSON.stringify(true)));
                    setLicenseToken();
                }
            });
        });
    });
}
