import React from 'react';
import parse from 'html-react-parser';
import { Box, } from '@chakra-ui/react';
// Register : Modal for terms of use
export const Presentation = ({ t, logo_sankey_plus }) => {
    const html_text = parse(t('Register.presentation.text'));
    return React.createElement(Box, null,
        React.createElement(Box, { as: 'span' },
            React.createElement("center", null,
                React.createElement("img", { src: logo_sankey_plus, alt: 'logo_OSP', style: { 'objectFit': 'contain', 'height': '100px', 'marginBottom': '10px' } }))),
        React.createElement(Box, { as: 'span' }, html_text));
};
