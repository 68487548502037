import React from 'react';
import { Navigate } from 'react-router-dom';
import { loginComponent } from '../LoginComponent';
export const PrivateRoute = ({ component, }) => {
    if (loginComponent().has_account) {
        return component;
    }
    return React.createElement(Navigate, { to: "/" });
};
export const LoginRoute = ({ component, }) => {
    if (loginComponent().has_account) {
        return component;
    }
    return React.createElement(Navigate, { to: "/login" });
};
