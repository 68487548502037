// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// All rights reserved for TerriFlux
// ==================================================================================================
// External imports =================================================================================
import React, { useEffect, useRef, useState } from 'react';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Center, ChakraProvider, Spinner } from '@chakra-ui/react';
// OpenSankey imports ===============================================================================
import OpenSankeyApp from './deps/MFASankey/deps/OpenSankey+/deps/OpenSankey/App';
import { ClickSaveDiagram } from './deps/MFASankey/deps/OpenSankey+/deps/OpenSankey/components/dialogs/SankeyPersistence';
import { initializeMenuConfiguration } from './deps/MFASankey/deps/OpenSankey+/deps/OpenSankey/Modules';
// OpenSankey+ imports ===============================================================================
import { initializeDiagrammSelectorOSP, initializeReinitializationOSP, } from './deps/MFASankey/deps/OpenSankey+/ModulesOSP';
import { ModalWelcomeBuilderOSP } from './deps/MFASankey/deps/OpenSankey+/components/welcome/ModalWelcomeOSP';
import { Theme_SankeyApplication } from './chakra/Theme';
import { initializeAdditionalMenusSA, initializeApplicationDataSA, moduleDialogsSA } from './ModulesSA';
import Account from './deps/LoginComponent/UserPages/Account';
import Dashboard from './deps/LoginComponent/UserPages/Dashboard';
import Register from './deps/LoginComponent/Register/Register';
import { Login } from './deps/LoginComponent/Login/Login';
import { PasswordResetFromMail, PasswordResetFromToken } from './deps/LoginComponent/Login/PasswordReset';
import { LoginRoute, PrivateRoute } from './deps/LoginComponent/Routes/PrivateRoutes';
import { PublicRoute } from './deps/LoginComponent/Routes/PublicRoutes';
import { PaiementCheckout, PaiementPage, PaiementReturn } from './deps/LoginComponent/Paiement/Paiement';
import { loginComponent } from './deps/LoginComponent/LoginComponent';
import { MetaTags } from './components/MetaTags';
export const SankeyApp = ({ new_data_app }) => {
    const setUpdate = useRef(() => {
        new_data_app.has_sankey_plus = loginComponent().has_account;
        new_data_app.has_mfa = loginComponent().has_account;
        //new_data_app.menu_configuration.updateComponentsRelatedToSA()
        new_data_app.menu_configuration.updateAllMenuComponents();
    });
    // Minimal app ------------------------------------------------------------------------------------
    const sankeyApp = React.createElement(OpenSankeyApp, { initializeReinitialization: initializeReinitializationOSP, initializeApplicationData: (initial_data) => {
            return initializeApplicationDataSA(new_data_app, initial_data);
        }, initializeMenuConfiguration: initializeMenuConfiguration, initializeAdditionalMenus: (additionalMenus, new_data) => {
            initializeAdditionalMenusSA(additionalMenus, new_data, setUpdate);
        }, initializeDiagrammSelector: initializeDiagrammSelectorOSP, moduleDialogs: moduleDialogsSA, ModalWelcome: ModalWelcomeBuilderOSP, ClickSaveDiagram: (new_data_app) => { ClickSaveDiagram(new_data_app); } });
    if (new_data_app.is_static)
        return React.createElement(ChakraProvider, { theme: Theme_SankeyApplication }, sankeyApp);
    // Full app ------------------------------------------------------------------------------------
    const video_src = 'media/catch_phrase_OpenSankey.v' + String(Math.round(Math.random() * 3)) + '.webm';
    const [app, setApp] = useState(React.createElement(HelmetProvider, null,
        React.createElement(MetaTags, { new_data_app: new_data_app }),
        React.createElement(ChakraProvider, { theme: Theme_SankeyApplication },
            React.createElement(Center, { h: window.innerHeight, w: window.innerWidth, display: "grid", gridAutoFlow: "row" },
                React.createElement(Spinner, { size: 'xl' })))));
    const exemple_menu = {};
    // if (!new_data_app?.is_static) {
    //   // Menus are not presents in mode publish
    //   const path = window.location.origin
    //   const url = path + '/opensankey/sankey/menu_examples'
    //   // let formations_menu = {} as { [_: string]: JSX.Element }
    //   fetch(url, fetchData).then(response => {
    //     response.text().then(text => {
    //       const json_data = JSON.parse(text)
    //       exemple_menu = json_data.exemples_menu
    //       if (Object.keys(json_data.exemples_menu['Formations']).length > 0) {
    //         // formations_menu = Object.fromEntries(
    //         //   Object.entries(json_data.exemples_menu['Formations']['Tutoriels']).filter(d => d[0] !== 'artefacts')
    //         // ) as { [_: string]: JSX.Element }
    //         delete json_data.exemples_menu['Formations']['Tutoriels']
    //       }
    //     }).catch(() => {
    //       exemple_menu = {}
    //       // formations_menu = {}
    //     }).then(() => {
    //       renderPage()
    //     })
    //   })
    // }
    const blockers = {};
    useEffect(() => {
        setTimeout(() => {
            loginComponent().checkTokens(setUpdate)
                .then(() => setApp(React.createElement(HelmetProvider, null,
                React.createElement(MetaTags, { new_data_app: new_data_app }),
                React.createElement(ChakraProvider, { theme: Theme_SankeyApplication },
                    React.createElement(HashRouter, null,
                        React.createElement(Routes, null,
                            React.createElement(Route, { path: '/register', element: React.createElement(PublicRoute, { component: React.createElement(Register, { t: new_data_app.t, logo: new_data_app.logo, logo_sankey_plus: new_data_app.logo_mfa, loginComponent: loginComponent, noLicenceAccountRequired: true, setUpdate: setUpdate, returnToApp: returnToApp, theme: Theme_SankeyApplication }) }) }),
                            React.createElement(Route, { path: '/login' },
                                React.createElement(Route, { index: true, element: React.createElement(PublicRoute, { component: React.createElement(Login, { t: new_data_app.t, logo: new_data_app.logo, loginComponent: loginComponent, setUpdate: setUpdate, returnToApp: returnToApp, noLicenceAccountRequired: true }) }) }),
                                React.createElement(Route, { path: 'forgot', element: React.createElement(PublicRoute, { component: React.createElement(PasswordResetFromMail, { t: new_data_app.t, logo: new_data_app.logo, returnToApp: returnToApp }) }) }),
                                React.createElement(Route, { path: 'reset/:token', element: React.createElement(PublicRoute, { component: React.createElement(PasswordResetFromToken, { t: new_data_app.t, logo: new_data_app.logo, returnToApp: returnToApp }) }) })),
                            React.createElement(Route, { path: '/dashboard', element: React.createElement(PrivateRoute, { component: React.createElement(Dashboard, { t: new_data_app.t, logo: new_data_app.logo, returnToApp: returnToApp, loginComponent: loginComponent, setUpdate: setUpdate, exemple_menu: exemple_menu }) }) }),
                            React.createElement(Route, { path: '/license' },
                                React.createElement(Route, { index: true, element: React.createElement(PrivateRoute, { component: React.createElement(PaiementPage, { t: new_data_app.t, logo: new_data_app.logo, returnToApp: returnToApp, logo_sankey_plus: new_data_app.logo_mfa }) }) }),
                                React.createElement(Route, { path: 'checkout', element: React.createElement(PrivateRoute, { component: React.createElement(PaiementCheckout, null) }) }),
                                React.createElement(Route, { path: 'return', element: React.createElement(PrivateRoute, { component: React.createElement(PaiementReturn, null) }) })),
                            React.createElement(Route, { path: '/account', element: React.createElement(PrivateRoute, { component: React.createElement(Account, { t: new_data_app.t, logo: new_data_app.logo, logo_sankey_plus: new_data_app.logo_mfa, returnToApp: returnToApp, loginComponent: loginComponent, noLicenceAccountRequired: true, setUpdate: setUpdate, blocker_suite_sankey: blockers }) }) }),
                            React.createElement(Route, { path: '/', element: React.createElement(LoginRoute, { component: sankeyApp }) }),
                            React.createElement(Route, { path: "*", element: React.createElement(Navigate, { to: "/" }) })))))));
        }, 1000);
    }, []);
    return app;
};
export const returnToApp = (navigate) => {
    navigate('/');
};
