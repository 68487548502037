// ==================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
// Date : 28/08/2024
// All rights reserved for TerriFlux
// ==================================================================================================
// External imports
import * as d3 from 'd3';
import { useRef } from 'react';
// OpenSankey imports
import { Class_MenuConfig } from '../deps/OpenSankey/types/MenuConfig';
// CLASS MENU CONFIG PLUS ***************************************************************
/**
 * Override OpenSankey's Class_MenuConfig to take in account specifities of OpenSankey+ app
 *
 * @export
 * @class Class_MenuConfigOSP
 * @extends {Class_MenuConfig}
 */
export class Class_MenuConfigOSP extends Class_MenuConfig {
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_MenuConfigOSP.
     * @memberof Class_MenuConfigOSP
     */
    constructor() {
        // Init parent class
        super();
        //Var used for the dataTagg sequence component
        this._is_playing_sequence = false;
        this._is_sequence_loop = false;
        // Timeout between steps in sequence (in ms)
        this._timeout_sequence = 2000;
        // Init value for menu_config plus variable
        this._ref_to_menu_config_container_updater = useRef(() => null);
        this._ref_to_banner_views_updater = useRef(() => null);
        this._ref_to_accordion_views_updater = useRef(() => null);
        this._ref_to_accordion_containers_updater = useRef(() => null);
        this._ref_to_save_diagram_only_view_updater = useRef(() => null);
        this._ref_to_drawer_sequence_data_tag_updater = useRef(() => null);
        this._ref_to_checkbox_pref_view_updater = useRef(() => null);
        this._ref_to_checkbox_pref_edition_tags_updater = useRef(() => null);
        this._ref_to_checkbox_pref_container_updater = useRef(() => null);
        this._ref_to_modal_view_attributes_switcher = useRef((_) => null);
        this._zdt_accordion_ref = useRef(null);
        this._r_setter_editor_content_fo_node = useRef(() => null);
        this._r_editor_content_fo_node_updater = useRef(() => null);
        this._ref_to_menu_config_node_name_label_bg_updater = useRef(() => null);
        this._ref_to_menu_config_link_data_text_updater = useRef(() => null);
        this._ref_to_menu_config_link_scientific_precision_updater = useRef(() => null);
        this._ref_to_toolbar_node_tag_updater = useRef(() => null);
        this._ref_to_toolbar_link_tag_updater = useRef(() => null);
        this._ref_to_toolbar_data_tag_updater = useRef(() => null);
        this._ref_to_toolbar_level_tag_filter_updater = useRef(() => null);
        this._ref_to_accordion_edition_tag_updater = useRef(() => null);
        this._ref_to_toolbar_link_visual_filter_updater = useRef(() => null);
        this._ref_to_config_DA_bg_image_updater = useRef(() => null);
        this._ref_to_menu_config_node_icon_updater = useRef(() => null);
        this._ref_to_updater_modal_apply_layout_plus = useRef(() => null);
        this._dict_setter_show_dialog_plus = {
            ref_setter_show_menu_node_icon: useRef(() => null),
            ref_setter_show_modal_import_icons: useRef(() => null),
            ref_setter_show_menu_zdt: useRef(() => null),
            ref_setter_show_menu_view_not_saved: useRef(() => null)
        };
    }
    // PUBLIC METHODS ====================================================================
    /**
     * Override updateAllMenuComponents to take into account menu for OSP
     *
     * @memberof Class_MenuConfigOSP
     */
    updateAllMenuComponents() {
        super.updateAllMenuComponents();
        this.updateComponentRelatedToContainers();
        this.updateComponentRelatedToViews();
        this._ref_to_config_DA_bg_image_updater.current();
    }
    openConfigMenuElementsContainers() {
        var _a;
        this.openConfigMenuElements();
        (_a = this._zdt_accordion_ref.current) === null || _a === void 0 ? void 0 : _a.click();
        // Leave enough time for menus to open
        setTimeout(() => {
            // Open Free labels element menu
            if (this._zdt_accordion_ref.current &&
                (d3.select(this._zdt_accordion_ref.current).attr('aria-expanded') === 'false')) {
                this._zdt_accordion_ref.current.click();
            }
        }, 200);
    }
    updateComponentPref() {
        super.updateComponentPref();
        this._ref_to_accordion_containers_updater.current();
        this._ref_to_checkbox_pref_view_updater.current();
        this._ref_to_checkbox_pref_container_updater.current();
        this._ref_to_checkbox_pref_edition_tags_updater.current();
    }
    updateComponentSaveDiagramJSON() {
        super.updateComponentSaveDiagramJSON();
        this.ref_to_save_diagram_only_view_updater.current();
    }
    updateAllComponentsRelatedToToolbar() {
        super.updateAllComponentsRelatedToToolbar();
        this._ref_to_toolbar_link_visual_filter_updater.current();
        this.ref_to_toolbar_node_tag_updater.current();
        this.ref_to_toolbar_link_tag_updater.current();
        this.ref_to_toolbar_data_tag_updater.current();
        this.ref_to_leveltag_filter_updater.current();
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToContainers() {
        this._add_waiting_process('updateComponentRelatedToContainers', (_this) => {
            _this._ref_to_menu_config_container_updater.current();
            _this._ref_to_accordion_containers_updater.current();
        });
    }
    /**
     * Update Components related to views (BannerView, AccordionView,...)
     *
     * @memberof Class_MenuConfigOSP
     */
    updateComponentRelatedToViews() {
        this._add_waiting_process('updateComponentRelatedToView', (_this) => {
            _this._ref_to_banner_views_updater.current();
            _this._ref_to_accordion_views_updater.current();
        });
    }
    /**
     * Create a timed out process - Used to avoid multiple reloading of components
     *
     * The process_func is meant to be use by setTimeout(),
     * and inside setTimeOut 'this' keyword has another meaning,
     * so the current object must be passed directly as an argument.
     * see : https://developer.mozilla.org/en-US/docs/Web/API/setTimeout#the_this_problem
     *
     * @public
     * @param {string} process_id
     * @param {(_: Class_MenuConfig) => void} process_func
     * @memberof Class_MenuConfig
     */
    _add_waiting_process(process_id, process_func) {
        this._cancel_waiting_process(process_id);
        this._waiting_processes[process_id] = setTimeout((_this) => { process_func(_this); }, this._waiting_time_for_processes, this);
    }
    updateAllComponentsRelatedToNodesConfig() {
        super.updateAllComponentsRelatedToNodesConfig();
        this._r_editor_content_fo_node_updater.current();
        this._ref_to_menu_config_node_name_label_bg_updater.current();
    }
    updateComponentRelatedToLinksApparence() {
        this._add_waiting_process('updateComponentRelatedToLinksApparence', (_this) => {
            _this.ref_to_menu_config_links_apparence_updater.current();
            this._ref_to_menu_config_link_scientific_precision_updater.current();
        });
    }
    updateAllComponentsRelatedToTags() {
        super.updateAllComponentsRelatedToTags();
        this._ref_to_toolbar_node_tag_updater.current();
        this._ref_to_toolbar_link_tag_updater.current();
        this._ref_to_toolbar_data_tag_updater.current();
    }
    updateAllComponentsRelatedToNodeTags() {
        super.updateAllComponentsRelatedToNodeTags();
        this._ref_to_toolbar_node_tag_updater.current();
        this._ref_to_toolbar_level_tag_filter_updater.current();
    }
    updateAllComponentsRelatedToFluxTags() {
        super.updateAllComponentsRelatedToFluxTags();
        this._ref_to_toolbar_link_tag_updater.current();
    }
    updateAllComponentsRelatedToDataTags() {
        super.updateAllComponentsRelatedToDataTags();
        this._ref_to_toolbar_data_tag_updater.current();
        this._ref_to_drawer_sequence_data_tag_updater.current();
    }
    updateAllComponentsRelatedToLevelTags() {
        super.updateAllComponentsRelatedToLevelTags();
        this._ref_to_toolbar_level_tag_filter_updater.current();
        this._ref_to_drawer_sequence_data_tag_updater.current();
    }
    /**
     * Update component with timeOut to avoid multiple refreshs
     * @memberof Class_MenuConfig
     */
    updateComponentRelatedToLinksData() {
        this._add_waiting_process('updateComponentRelatedToLinksData', (_this) => {
            _this.ref_to_menu_config_links_data_updater.current();
            _this.ref_to_menu_contextual_config_links_data_updater.current();
            _this._ref_to_menu_config_link_data_text_updater.current();
        });
    }
    updateMenuConfigComponent() {
        this.ref_to_accordion_edition_tag_updater.current();
        super.updateMenuConfigComponent();
    }
    updateComponentApplyLayout() {
        super.updateComponentApplyLayout();
        this._ref_to_updater_modal_apply_layout_plus.current();
    }
    /**
     * Launch datatagg sequence, it go through each tag of a group and draw sankey
     *
     * @param {Class_DataTagGroup} tagg
     * @memberof Class_MenuConfigOSP
     */
    launchDataSequence(tagg) {
        const curr_tag = tagg.first_selected_tags;
        const tagg_list = tagg.tags_list;
        if (curr_tag && this._is_playing_sequence && tagg_list.length > 1) {
            const idx_curr_tag = tagg_list.indexOf(curr_tag);
            if (idx_curr_tag < tagg_list.length - 1) {
                // Draw sankey with next tag selected
                const next_tag = tagg_list[idx_curr_tag + 1];
                tagg.selectTagsFromId(next_tag.id);
                // Lauch timeout to recursively call launchDataSequence
                setTimeout(() => {
                    this.updateAllComponentsRelatedToDataTags();
                    this.launchDataSequence(tagg);
                }, this._timeout_sequence);
            }
            //If we are at the last tag of the group & loop sequence is at true then select first tag of the group
            else if (this._is_sequence_loop && idx_curr_tag == tagg_list.length - 1) {
                // Draw sankey with first tag of the group
                const first_tag = tagg_list[0];
                tagg.selectTagsFromId(first_tag.id);
                // Lauch timeout to recursively call launchDataSequence
                setTimeout(() => {
                    this.updateAllComponentsRelatedToDataTags();
                    this.launchDataSequence(tagg);
                }, this._timeout_sequence);
            }
            else { //get here when there is no next tag
                this._is_playing_sequence = false;
                this.updateAllComponentsRelatedToDataTags();
            }
        }
        else { //get here when there curr_tag is undefined wich can be an error or we stop the sequence
            this._is_playing_sequence = false;
            this.updateAllComponentsRelatedToDataTags();
        }
    }
    // PROTECTED METHODS ==================================================================
    _updateComponentRelatedToNodesApparence() {
        super._updateComponentRelatedToNodesApparence();
        this._ref_to_menu_config_node_icon_updater.current();
    }
    // GETTERS / SETTERS ==================================================================
    get zdt_accordion_ref() { return this._zdt_accordion_ref; }
    get dict_setter_show_dialog_plus() { return this._dict_setter_show_dialog_plus; }
    // public set dict_setter_show_dialog_plus(value: OSPShowMenuComponentsVarType) {this._dict_setter_show_dialog_plus = value;}
    // public get ref_to_navbar_views_updater(): MutableRefObject<() => void>  { return this._ref_to_navbar_views_updater }
    // public get ref_to_selector_views_updater(): MutableRefObject<() => void> { return this._ref_to_selector_views_updater }
    get ref_to_menu_config_containers_updater() { return this._ref_to_menu_config_container_updater; }
    get r_setter_editor_content_fo_node() { return this._r_setter_editor_content_fo_node; }
    get ref_to_banner_views_updater() { return this._ref_to_banner_views_updater; }
    get ref_to_accordion_views_updater() { return this._ref_to_accordion_views_updater; }
    get ref_to_modal_view_attributes_switcher() { return this._ref_to_modal_view_attributes_switcher; }
    get ref_to_accordion_containers_updater() { return this._ref_to_accordion_containers_updater; }
    get ref_to_save_diagram_only_view_updater() { return this._ref_to_save_diagram_only_view_updater; }
    get r_editor_content_fo_node_updater() { return this._r_editor_content_fo_node_updater; }
    get ref_to_menu_config_node_name_label_bg_updater() { return this._ref_to_menu_config_node_name_label_bg_updater; }
    get ref_to_menu_config_link_data_text_updater() { return this._ref_to_menu_config_link_data_text_updater; }
    get ref_to_menu_config_link_scientific_precision_updater() { return this._ref_to_menu_config_link_scientific_precision_updater; }
    get ref_to_toolbar_node_tag_updater() { return this._ref_to_toolbar_node_tag_updater; }
    get ref_to_toolbar_link_tag_updater() { return this._ref_to_toolbar_link_tag_updater; }
    get ref_to_toolbar_data_tag_updater() { return this._ref_to_toolbar_data_tag_updater; }
    get ref_to_toolbar_level_tag_filter_updater() { return this._ref_to_toolbar_level_tag_filter_updater; }
    get ref_to_toolbar_link_visual_filter_updater() { return this._ref_to_toolbar_link_visual_filter_updater; }
    get ref_to_accordion_edition_tag_updater() { return this._ref_to_accordion_edition_tag_updater; }
    get ref_to_config_DA_bg_image_updater() { return this._ref_to_config_DA_bg_image_updater; }
    get ref_to_menu_config_node_icon_updater() { return this._ref_to_menu_config_node_icon_updater; }
    get ref_to_updater_modal_apply_layout_plus() { return this._ref_to_updater_modal_apply_layout_plus; }
    get ref_to_drawer_sequence_data_tag_updater() { return this._ref_to_drawer_sequence_data_tag_updater; }
    get is_playing_sequence() { return this._is_playing_sequence; }
    set is_playing_sequence(b) { this._is_playing_sequence = b; }
    get is_sequence_loop() { return this._is_sequence_loop; }
    set is_sequence_loop(value) { this._is_sequence_loop = value; }
    get timeout_sequence() { return this._timeout_sequence; }
    set timeout_sequence(value) { this._timeout_sequence = value; }
    get ref_to_checkbox_pref_view_updater() { return this._ref_to_checkbox_pref_view_updater; }
    get ref_to_checkbox_pref_container_updater() { return this._ref_to_checkbox_pref_container_updater; }
    get ref_to_checkbox_pref_edition_tags_updater() { return this._ref_to_checkbox_pref_edition_tags_updater; }
}
