import { Class_ApplicationDataMFA } from '../deps/MFASankey/types/TypesMFA';
import { Class_MenuConfigSA } from './MenuConfigSA';
export class Class_ApplicationDataSA extends Class_ApplicationDataMFA {
    // protected _has_account: boolean = false // token when user is connected with an account
    // protected _ok_to_check_account = true
    // protected _ok_to_check_account_timeout: NodeJS.Timeout | null = null
    // CONSTRUCTOR ========================================================================
    /**
     * Creates an instance of Class_ApplicationDataSA.
     * @param {boolean} published_mode
     * @memberof Class_ApplicationDataSA
     */
    constructor(published_mode, options = {}) {
        super(published_mode, options);
        // OVERRIDE
        this._menu_configuration = this.menu_configuration;
        // Default config on creation
        this._has_sankey_plus = false;
    }
    // PUBLIC METHODS =====================================================================
    createNewMenuConfiguration() {
        console.log('createNewMenuConfiguration');
        return new Class_MenuConfigSA();
    }
    // GETTERS / SETTERS ==================================================================
    // Override getter & setter so we can get new type
    get menu_configuration() { return this._menu_configuration; }
    set menu_configuration(_) { this._menu_configuration = _; }
    // Overrride logo
    get logo() {
        return this.logo_mfa;
    }
    get is_reconcilied() {
        return true;
    }
}
