import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Card, CardBody, CardHeader, Image, SimpleGrid } from '@chakra-ui/react';
import { LoginOutButton } from '../Login/Login';
// Dashboard, Register or Buy License
const Dashboard = ({ t, logo, returnToApp, loginComponent, setUpdate }) => {
    // Define navigation behaviour to return to App
    const navigate = useNavigate();
    //If we acces this page without being logged, it is resent to the application
    if (!loginComponent().has_account) {
        returnToApp(navigate);
    }
    //If we are log the the following behaviors are defined
    //Go to myAccount
    const myAccount = () => {
        navigate('/account');
    };
    // Displayed in card
    const modalTemplate = React.createElement(React.Fragment, null);
    return (React.createElement("div", null,
        React.createElement(Box, { zIndex: "1", position: "fixed", top: "0", width: "100%" },
            React.createElement(Box, { layerStyle: 'menutop_layout_style', gridTemplateColumns: 'minmax(7vw, 150px) auto' },
                React.createElement(Box, { margin: '0.25rem', alignSelf: 'center', justifySelf: 'left' },
                    React.createElement(Image, { height: '5rem', src: logo, alt: 'navigation logo', onClick: () => returnToApp(navigate) })),
                React.createElement(Box, { display: 'grid', gridTemplateColumns: '3fr 3fr 1fr', alignSelf: 'center', justifySelf: 'right' },
                    React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => returnToApp(navigate) }, t('UserNav.to_app')),
                    React.createElement(Button, { variant: 'btn_lone_navigation', onClick: () => myAccount() }, t('UserNav.to_acc')),
                    React.createElement(LoginOutButton, { t: t, logo: logo, returnToApp: returnToApp, loginComponent: loginComponent, setUpdate: setUpdate })))),
        React.createElement(Card, { variant: 'card_account' },
            React.createElement(CardHeader, null, t('UserPages.win_db_template')),
            React.createElement(CardBody, null,
                React.createElement(SimpleGrid, { spacing: 2, templateColumns: '1fr 1fr 1fr' }, modalTemplate)))));
};
export default Dashboard;
