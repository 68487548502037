import React from 'react';
import { Helmet } from 'react-helmet-async';
// You can have more props. In my case, these are enough.
export const MetaTags = ({ new_data_app }) => {
    return (React.createElement(Helmet, null,
        React.createElement("title", null, new_data_app.t('metatags.title')),
        React.createElement("link", { rel: 'canonical', href: window.location.href }),
        React.createElement("meta", { name: "keywords", content: new_data_app.t('metatags.kw') }),
        React.createElement("meta", { name: 'description', content: new_data_app.t('metatags.descr') }),
        React.createElement("meta", { name: "subject", content: new_data_app.t('metatags.subject') }),
        React.createElement("meta", { property: "og:url", content: window.location.href }),
        React.createElement("meta", { property: "og:type", content: "website" }),
        React.createElement("meta", { property: "og:title", content: new_data_app.t('metatags.title') }),
        React.createElement("meta", { property: "og:description", content: new_data_app.t('metatags.descr') }),
        React.createElement("meta", { property: "og:image", content: new_data_app.t('metatags.image') }),
        React.createElement("meta", { property: "og:image:secure_url", content: new_data_app.t('metatags.image') }),
        React.createElement("meta", { property: "og:image:type", content: "image/jpeg" }),
        React.createElement("meta", { property: "og:image:width", content: "200" }),
        React.createElement("meta", { property: "og:image:alt", content: 'Image of ' + new_data_app.t('metatags.title') + ' site' }),
        React.createElement("meta", { name: "twitter:creator", content: new_data_app.t('metatags.name') }),
        React.createElement("meta", { name: "twitter:title", content: new_data_app.t('metatags.title') }),
        React.createElement("meta", { name: "twitter:description", content: new_data_app.t('metatags.descr') })));
};
