// ================================================================================================
// Authors :
//  - Vincent CLAVEL
//  - Julien ALAPETITE
//  - Vincent LE DOZE
//
// Main for SankeyApplication
//
// All rights reserved for TerriFlux
// ================================================================================================
// CSS ============================================================================================
import './deps/MFASankey/deps/OpenSankey+/deps/OpenSankey/css/main.css';
import './deps/MFASankey/deps/OpenSankey+/deps/OpenSankey/css/bootstrap.css';
import './deps/MFASankey/deps/OpenSankey+/css/main.css';
import './deps/MFASankey/deps/OpenSankey+/css/colors/red.css';
import './deps/MFASankey/deps/OpenSankey+/css/style_elements_sankey.css';
import './deps/MFASankey/deps/OpenSankey+/css/react-quill.css';
import './css/Login.css';
import './css/Register.css';
// External imports ===============================================================================
import React from 'react';
import { createRoot } from 'react-dom/client';
import './traductions/traduction';
import i18next from './traductions/traduction';
// Local modules =================================================================================
import { SankeyApp } from './AppSA';
import { Class_ApplicationDataSA } from './types/ApplicationDataSA';
window.React = React;
i18next.changeLanguage(navigator.language.includes('fr') ? 'fr' : 'en');
const container = document.getElementById('react-container');
const root = createRoot(container);
let initialRender = true;
let dataApp;
const App = () => {
    if (initialRender) {
        initialRender = false;
        dataApp = new Class_ApplicationDataSA(window.SankeyToolsStatic == true);
        if (window.sankey && window.sankey.filiere) {
            dataApp.fromJSON(window.sankey.filiere);
        }
    }
    return React.createElement(SankeyApp, { new_data_app: dataApp });
};
const renderPage = () => {
    root.render(React.createElement(App, null));
};
renderPage();
