var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * Get stripe config code
 * @return {*}
 */
export function getStripePublishableKey() {
    return __awaiter(this, void 0, void 0, function* () {
        // Get server api url
        const path = window.location.origin;
        const url = path + '/stripe/config';
        // use server as proxy to fetch informations
        // -> Avoid "Same-Origin" problem with CORS
        return fetch(url)
            .then(response => {
            if (response.ok)
                return response.json();
            else
                return Promise.reject(response);
        })
            .then(response_json => {
            return response_json.publicKey;
        });
    });
}
/**
 * Get stripe subscription infos
 * @return {*}
 */
export function createSubscription() {
    return __awaiter(this, void 0, void 0, function* () {
        // Get server api url
        const path = window.location.origin;
        const url = path + '/stripe/create-checkout-session/osplus';
        // use server as proxy to fetch informations
        // -> Avoid "Same-Origin" problem with CORS
        return fetch(url, { method: 'POST' })
            .then(response => {
            if (response.ok)
                return response.json();
            else
                return Promise.reject(response);
        })
            .then(response_json => {
            return response_json.clientSecret;
        });
    });
}
